import React, { useState } from 'react';
import styled from 'styled-components';

const faqData = [
  {
    question: 'O que é energia solar fotovoltaica?',
    answer: 'Energia solar fotovoltaica é uma forma de gerar eletricidade a partir da luz do sol, utilizando painéis solares que convertem a radiação solar em energia elétrica.',
  },
  {
    question: 'Quais são os benefícios da energia solar?',
    answer: 'A energia solar reduz significativamente os custos com eletricidade, é sustentável e contribui para a preservação do meio ambiente ao reduzir a emissão de carbono.',
  },
  {
    question: 'Quanto posso economizar com energia solar?',
    answer: 'A economia pode variar, mas em geral, é possível reduzir até 95% da conta de energia elétrica.',
  },
  {
    question: 'Qual é a durabilidade dos painéis solares?',
    answer: 'Os painéis solares têm uma vida útil de cerca de 25 a 30 anos, com manutenção mínima ao longo do tempo.',
  },
];

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <SectionContainer>
      <SectionTitle>Perguntas Frequentes (FAQ)</SectionTitle>
      <FAQList>
        {faqData.map((item, index) => (
          <FAQItem key={index}>
            <Question onClick={() => toggleAnswer(index)}>
              {item.question}
            </Question>
            {activeIndex === index && <Answer>{item.answer}</Answer>}
          </FAQItem>
        ))}
      </FAQList>
    </SectionContainer>
  );
};

export default FAQSection;

// Styled Components

const SectionContainer = styled.section`
  padding: 60px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};
`;

const FAQList = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
  text-align: center; /* Altera para centralizar o conteúdo */
  padding: 0 20px; /* Ajusta o padding para melhorar a margem em telas menores */
`;

const FAQItem = styled.li`
  margin-bottom: 20px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
`;

const Question = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  text-align: center; /* Centraliza a pergunta */

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const Answer = styled.p`
  font-size: 1rem;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.6;
  text-align: left; /* Mantém o texto da resposta alinhado à esquerda */
`;
