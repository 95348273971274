import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import video1 from '../assets/video5.mp4';
import video2 from '../assets/video6.mp4';
import video3 from '../assets/video7.mp4';
import video4 from '../assets/video8.mp4';
import video5 from '../assets/video9.mp4';
import video6 from '../assets/video10.mp4';
import video7 from '../assets/video11.mp4';
import video8 from '../assets/video12.mp4';
import video9 from '../assets/video13.mp4';

const ProjectsSection = () => {
  const video1Ref = useRef(null); // Usar useRef para referenciar o video1

  const projects = [
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video6,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video7,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video8,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video9,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video1,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
      ref: video1Ref, // Referência para video1
    },
    {
      title: 'Projeto Comercial',
      description: 'Sistemas fotovoltaicos instalados em uma faculdade.',
      video: video5,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Comercial',
      description: 'Instalações de energia solar em edifícios comerciais.',
      video: video2,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video3,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    },
    {
      title: 'Projeto Industrial',
      description: 'Soluções de energia solar para grandes indústrias.',
      video: video4,
      link: 'https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==',
    }
  ];

  const [currentProject, setCurrentProject] = useState(0);

  const nextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
  };

  const handleLoadedMetadata = (videoRef) => {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = 9; // Definir tempo de início para 9 segundos
    }
  };
  const handleTimeUpdate = (videoRef) => {
    if (videoRef && videoRef.current) {
      // Ensure the video never plays from a time earlier than 9 seconds
      if (videoRef.current.currentTime < 9) {
        videoRef.current.currentTime = 9;
      }
    }
  };
  return (
    <SectionContainer>
      <ProjectsTitle>Projetos</ProjectsTitle> 
      <CarouselContainer>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            className={
              index === currentProject
                ? 'active'
                : index === (currentProject - 1 + projects.length) % projects.length
                ? 'left'
                : 'right'
            }
          >
            <ProjectVideo
              ref={project.ref || null} // Anexar ref apenas ao video1
              src={project.video}
              autoPlay
              muted
              loop
              alt={project.title}
              onLoadedMetadata={() => project.ref && handleLoadedMetadata(project.ref)} // Somente chama handleLoadedMetadata se o ref existir
              onTimeUpdate={() => handleTimeUpdate(project.ref)} // Update time every frame
         
            />
            <ProjectOverlay>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ViewButton
                href={project.link}
                target="_blank" // Abre em uma nova aba
                rel="noopener noreferrer" // Adiciona segurança ao link externo
              >Ver projeto</ViewButton>
            </ProjectOverlay>
          </ProjectCard>
        ))}
      </CarouselContainer>
      <Navigation>
        <Arrow onClick={prevProject}>&lt;</Arrow>
        <Arrow onClick={nextProject}>&gt;</Arrow>
      </Navigation>
    </SectionContainer>
  );
};

export default ProjectsSection;

// Styled Components permanecem iguais


// Styled Components

const SectionContainer = styled.section`
  padding: 300px 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const ProjectsTitle = styled.h2`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
  position: relative;
  perspective: 1000px;
`;

const ProjectCard = styled.div`
  position: absolute;
  width: 300px;
  height: 400px;
  margin-bottom: 100px;
  perspective: 1000px;
  transition: all 0.8s ease;
  transform-style: preserve-3d;
  cursor: pointer;

  &.active {
    transform: scale(1.2) translateZ(200px);
    z-index: 10;
    opacity: 1;
  }

  &.left {
    transform: rotateY(20deg) translateX(-200px) scale(0.9);
    z-index: 5;
    opacity: 0.5;
  }

  &.right {
    transform: rotateY(-20deg) translateX(200px) scale(0.9);
    z-index: 5;
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.1) translateZ(150px);
  }

  @media (max-width: 768px) {
    width: 250px;
    height: 350px;

    &.active {
      transform: scale(1.1) translateZ(150px);
    }
  }
`;

const ProjectVideo = styled.video`
  width: 100%;
  height: 100%;
  margin-bottom: 20px
  border-radius: 15px;
  object-fit: cover;
  transition: transform 0.5s, box-shadow 0.5s;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
`;

const ProjectOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 15px 15px;
  padding: 10px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  
  ${ProjectCard}:hover & {
    opacity: 1;
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};
`;

const ViewButton = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const Navigation = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
`;

const Arrow = styled.span`
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 40px;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
