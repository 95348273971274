import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import partnerLogo1 from '../assets/parceiros/aldo.png';
import partnerLogo2 from '../assets/parceiros/ibyte.jpg';
import partnerLogo3 from '../assets/parceiros/belenergi.png';
import partnerLogo4 from '../assets/parceiros/fortus.png';
import partnerLogo5 from '../assets/parceiros/bnb.png';
import partnerLogo6 from '../assets/parceiros/bvfinanceira.png';
import partnerLogo7 from '../assets/parceiros/credsol.jpg';
import partnerLogo8 from '../assets/parceiros/eos.jpg';
import partnerLogo9 from '../assets/parceiros/eurofinanceira.png';
import partnerLogo10 from '../assets/parceiros/sicredi.png';
import partnerLogo11 from '../assets/parceiros/solagora.png';
import partnerLogo12 from '../assets/parceiros/solfacil.png';

const partners = [
  { name: 'Aldo Solar', logo: partnerLogo1 },
  { name: 'GT Ibyte', logo: partnerLogo2 },
  { name: 'BelEnergy', logo: partnerLogo3 },
  { name: 'Fotus', logo: partnerLogo4 },
  { name: 'Banco do Nordeste', logo: partnerLogo5 },
  { name: 'BV Financeira', logo: partnerLogo6 },
  { name: 'CredSolaris', logo: partnerLogo7 },
  { name: 'EOS Financiamento Solar', logo: partnerLogo8 },
  { name: 'Euro Financeira', logo: partnerLogo9 },
  { name: 'Sicredi', logo: partnerLogo10 },
  { name: 'Sol Agora', logo: partnerLogo11 },
  { name: 'Solfacil', logo: partnerLogo12 },
];

const PartnershipSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000, // Velocidade de rotação lenta
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Velocidade zero para rotação contínua
    cssEase: 'linear', // Rolagem linear e suave
    pauseOnHover: false, // Não pausar ao passar o mouse
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SectionContainer>
      <SectionTitle>Parcerias e Certificações</SectionTitle>
      <SliderWrapper>
        <Slider {...settings}>
          {partners.map((partner, index) => (
            <PartnerCard key={index}>
              <PartnerLogo src={partner.logo} alt={partner.name} />
              <PartnerName>{partner.name}</PartnerName>
            </PartnerCard>
          ))}
        </Slider>
      </SliderWrapper>
    </SectionContainer>
  );
};

export default PartnershipSection;

// Styled Components

const SectionContainer = styled.section`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  overflow: hidden;
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};
`;

const SliderWrapper = styled.div`
  .slick-prev:before, .slick-next:before {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const PartnerCard = styled.div`
  text-align: center;
  width: 200px;
  margin: 0 20px;
`;

const PartnerLogo = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const PartnerName = styled.p`
  margin-top: 10px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
`;
