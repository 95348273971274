import React from 'react';
import styled from 'styled-components';

const Simulator = () => {
  return (
    <SimulatorContainer>
      <ContentWrapper>
        <CallToAction>
          <CallToActionTitle>Economize com Energia Solar</CallToActionTitle>
          <CallToActionText>
            Descubra quanto você pode economizar utilizando nossa solução de energia solar. Clique no botão abaixo para saber mais!
          </CallToActionText>
          <CallToActionButton
            href="https://azume.com.br/institucional/iframesrchttpsazumecombrsimulador645bd52a770a4b0014f497a7width100height600iframe" // Altere para o link desejado
            target="_blank"
            rel="noopener noreferrer"
          >
            Saiba Mais
          </CallToActionButton>
        </CallToAction>

        <ImageContainer>
          <img src={require('../assets/image2.png')} alt="Imagem gerada" />
        </ImageContainer>
      </ContentWrapper>
    </SimulatorContainer>
  );
};

export default Simulator;

// Styled Components

const SimulatorContainer = styled.div`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const CallToAction = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 40px;

  @media (max-width: 768px) {
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const CallToActionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

const CallToActionText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.background};
`;

const CallToActionButton = styled.a`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 12px 24px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
