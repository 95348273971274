// src/theme.js
export const theme = {
    colors: {
      background: '#07020D', // Fundo escuro (BLACK)
      background_second: '#60AFFF', // Fundo escuro (BLACK)
      primary: '#ff4500', // Cor de destaque
      text: '#ffffff', // Cor principal do texto
      secondaryText: '#ccc', // Texto secundário
      cardBackground: '#2c2c2e', // Fundo dos cards
      borderColor: '#333',
      white: '#ffffff',
    },
    fonts: {
      main: "'Roboto', sans-serif",
    },
  };
  