import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.png'; // Certifique-se de ajustar o caminho correto para a logo
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <Link to="/">
            <img src={logo} alt="Logo da Empresa" />
          </Link>
          <h2>GF Soluções de <br />Engenharia</h2>
          <p>&copy; 2024. Todos os direitos reservados.</p>
        </LogoContainer>
        <FooterLinks>
          <FooterColumn>
            <ColumnTitle>Links Úteis</ColumnTitle>
            <Link to="/">
              <FooterLink>Início</FooterLink>
            </Link>
            <Link to="/about">
              <FooterLink>Sobre Nós</FooterLink>
            </Link>
            <Link to="/projects">
              <FooterLink>Serviços</FooterLink>
            </Link>
            <Link to="/contact">
              <FooterLink>Contato</FooterLink>
            </Link>
          </FooterColumn>
          <FooterColumn>
            <ColumnTitle>Contato</ColumnTitle>
            <ContactInfo>Email: contato@gfsolucoes.com</ContactInfo>
            <ContactInfo>Telefone: (85) 98202-5839</ContactInfo>
            <ContactInfo>Endereço: Rua. R, 123 - São Bento, Fortaleza - CE, 60875-610</ContactInfo>
          </FooterColumn>
          <FooterColumn>
            <ColumnTitle>Redes Sociais</ColumnTitle>
            <SocialIcons>
              <SocialIcon href="https://facebook.com" target="_blank">Facebook</SocialIcon>
              <SocialIcon href="https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==" target="_blank">Instagram</SocialIcon>
            </SocialIcons>
          </FooterColumn>
        </FooterLinks>
      </FooterContent>
      <DeveloperCredit>
        Desenvolvido por <a href="http://nexusoficial.com" target="_blank" rel="noopener noreferrer">Nexus</a>
      </DeveloperCredit>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: white;
  padding: 40px 20px;
  width: 100%;
  img {
    width: 150px;
    height: auto;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  text-align: left;

  h2 {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
  }

  p {
    margin: 0;
    color: #bbb;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const FooterLinks = styled.div`
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
`;

const ColumnTitle = styled.h3`
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const FooterLink = styled.a`
  display: block;
  color: #bbb;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContactInfo = styled.p`
  color: #bbb;
  margin: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const SocialIcon = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: 1.2rem;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const DeveloperCredit = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #bbb;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryHover};
    }
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
