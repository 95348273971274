import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Para redirecionar entre páginas
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify'; // Adicionar ToastContainer para exibir as notificações
import 'react-toastify/dist/ReactToastify.css';

const LoginModal = ({ closeModal }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Verifica o login do admin diretamente
    if (password === 'admin123') {
      // Redireciona para o painel do admin
      navigate('/admin');
      closeModal(); // Fecha o modal após o redirecionamento
      return;
    }

    try {
      // Faz a requisição para obter o cliente com base na senha
      const response = await axios.get(`http://localhost:5000/clients/password/${password}`);
      const client = response.data;

      // Se o cliente existir, redireciona para o painel do usuário com os dados do projeto
      if (client) {
        navigate('/user', { state: { client } }); // Passa os dados do cliente ao redirecionar
        closeModal(); // Fecha o modal
      } else {
        toast.error('Senha inválida');
      }
    } catch (error) {
      toast.error('Erro ao verificar login. Tente novamente.');
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ToastContainer /> {/* Exibe as notificações */}
        <CloseButton onClick={closeModal}>&times;</CloseButton> {/* Ícone de X no canto superior direito */}
        <h2>Entrar no Sistema</h2>
        <form onSubmit={handleLogin}>
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <SubmitButton type="submit">Entrar</SubmitButton>
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default LoginModal;


// Styled Components para o Modal

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative; /* Necessário para posicionar o botão de fechar */
  z-index: 1001; 
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 20px;
  box-sizing: border-box;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: red;
  }
`;
