import React from 'react';
import styled from 'styled-components';
import blogImage1 from '../assets/image1.jpg';
import blogImage2 from '../assets/image10.jpg';
import blogImage3 from '../assets/project1.jpg';
import blogImage4 from '../assets/project2.jpg';

const blogPosts = [
  {
    title: 'Dicas para economizar ainda mais com energia solar',
    description: 'Descubra como otimizar seu sistema de energia solar para maximizar a economia.',
    image: blogImage1,
    link: 'https://www.aldo.com.br/blog/conheca-7-dicas-para-economizar-com-energia-solar/',
  },
  {
    title: 'Os avanços mais recentes em tecnologia fotovoltaica',
    description: 'Confira as inovações mais recentes na tecnologia de painéis solares.',
    image: blogImage2,
    link: 'https://solucoes.edp.com.br/blog/tecnologia-em-usinas-fotovoltaicas/',
  },
  {
    title: 'Como calcular o retorno sobre o investimento em energia solar',
    description: 'Saiba como calcular o ROI de sistemas de energia solar em sua residência ou empresa.',
    image: blogImage3,
    link: 'https://www.bv.com.br/bv-inspira/financiamento-para-energia-solar/retorno-do-investimento-em-energia-solar#:~:text=Ou%20seja%3A%20o%20retorno%20do,e%20a%20taxa%20mínima%20cobrada.',
  },
  {
    title: 'Quais são os possíveis impactos da Reforma Tributária no setor solar?',
    description: 'A Reforma Tributária, discutida há décadas, tem como objetivo simplificar a cobrança de impostos no Brasil.',
    image: blogImage4,
    link: 'https://www.absolar.org.br/noticia/https-canalsolar-com-br-quais-sao-os-possiveis-impactos-da-reforma-tributaria-no-setor-solar/',
  },
];

const BlogSection = () => {
  return (
    <SectionContainer>
      <SectionTitle>Últimas Notícias e Dicas</SectionTitle>
      <BlogGrid>
        {blogPosts.map((post, index) => (
          <BlogCard key={index}>
            <BlogImage src={post.image} alt={post.title} />
            <BlogContent>
              <BlogTitle>{post.title}</BlogTitle>
              <BlogDescription>{post.description}</BlogDescription>
              <ReadMore 
               href={post.link}
               target="_blank"
               rel="noopener noreferrer"
             >Leia Mais</ReadMore>
            </BlogContent>
          </BlogCard>
        ))}
      </BlogGrid>
    </SectionContainer>
  );
};

export default BlogSection;

// Styled Components

const SectionContainer = styled.section`
  padding: 60px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BlogGrid = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 20px 0;
    margin: 0 10px 0 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const BlogCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 300px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    min-width: 250px;
    scroll-snap-align: start;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const BlogContent = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const BlogTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const BlogDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 15px 0;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ReadMore = styled.a`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
