import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import backgroundVideo from '../assets/video.mp4'// Importando o vídeo

const HeroSection = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false); // Para saber quando o vídeo está carregado

  const scrollToSection = () => {
    const section = document.getElementById('about-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Função que será chamada quando o vídeo for completamente carregado
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Section>
      {!isVideoLoaded && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <VideoBackground autoPlay loop muted onLoadedData={handleVideoLoad}>
        <source src={backgroundVideo} type="video/mp4" />
      </VideoBackground>
      <HeroContent>
        <HeroTitle>Economize com Energia Solar</HeroTitle>
        <HeroText>
          <strong>Explore soluções inovadoras que reduzem sua conta de luz e impulsionam um futuro sustentável.</strong>
        </HeroText>
        <CTAButton onClick={scrollToSection}>Saiba Mais</CTAButton>
      </HeroContent>
    </Section>
  );
};

export default HeroSection;

// Estilos com styled-components

const Section = styled.section`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* O vídeo vai cobrir toda a tela, mantendo a proporção */
  z-index: -1; /* Coloca o vídeo atrás do conteúdo */
`;

const HeroContent = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  z-index: 1; /* Coloca o conteúdo acima do vídeo */
`;

const HeroTitle = styled.h1`
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const HeroText = styled.p`
  font-size: 1.5rem;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.white};
  text-shadow: 
    -1px -1px 0 #000,  
    1px -1px 0 #000,  
    -1px 1px 0 #000,  
    1px 1px 0 #000; /* Isso cria a borda preta ao redor do texto */
`;

const CTAButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 40px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover || '#e63900'};
  }
`;

// Spinner Animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid ${({ theme }) => theme.colors.background};
  border-top: 8px solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

// Loading Overlay
const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Escurece o fundo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Coloca o overlay acima de tudo */
`;
