import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const MyContext = createContext();

// Criando o hook para acessar o contexto
export const useMyContext = () => useContext(MyContext);

// Criando o provider para envolver o app
export const MyContextProvider = ({ children }) => {
  const [state, setState] = useState('Valor inicial');

  return (
    <MyContext.Provider value={{ state, setState }}>
      {children}
    </MyContext.Provider>
  );
};
