import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FiMenu, FiX } from 'react-icons/fi';
import logo from '../assets/logo.png'; // Certifique-se de ajustar o caminho correto para a logo
import { Link } from 'react-router-dom';
import LoginModal from '../components/LoginModal'; // O Modal que criamos

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navRef = useRef();

  // Toggle do menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Função que fecha o menu ao clicar fora dele
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // useEffect para adicionar o listener de clique fora do menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/">
          <img src={logo} alt="Logo da Empresa" />
        </Link>
      </LogoContainer>
      <Nav ref={navRef}>
        <MenuToggle onClick={toggleMenu}>
          {isOpen ? <FiX size={30} /> : <FiMenu size={30} />}
        </MenuToggle>
        <NavList isOpen={isOpen}>
          <NavItem><NavLink href="/">Início</NavLink></NavItem>
          <NavItem><NavLink href="/about">Sobre Nós</NavLink></NavItem>
          <NavItem><NavLink href="/projects">Projetos</NavLink></NavItem>
          <NavItem><NavLink href="/simulator">Simulador</NavLink></NavItem>
          <NavItem><NavLink href="/contact">Contato</NavLink></NavItem>
          {/* <ActionButton onClick={() => setIsModalOpen(true)}>Entrar no Sistema</ActionButton> */}
        </NavList>
      </Nav>
      {isModalOpen && <LoginModal closeModal={() => setIsModalOpen(false)} />}
    </HeaderContainer>
  );
};

export default Header;

// Estilos com styled-components

const HeaderContainer = styled.header`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  position: relative;
  z-index: 1000;
`;

const LogoContainer = styled.div`
  img {
    width: 150px;
    height: auto;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const MenuToggle = styled.div`
  display: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  @media (max-width: 768px) {
    display: block;
  }
`;

const NavList = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;
  margin-right: 20px;
  transition: max-height 0.3s ease;

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.background};
    max-height: ${({ isOpen }) => (isOpen ? '400px' : '0')};
    overflow: hidden;
    transition: max-height 0.5s ease;
    z-index: 999; /* Garante que o menu ficará acima de outros elementos */
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

const NavItem = styled.li`
  @media (max-width: 768px) {
    text-align: center;
    padding: 10px 0;
  }
`;

const NavLink = styled.a`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.main};
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    font-size: 20px;
    display: block;
    width: 100%;
  }
`;

const ActionButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    width: 100%; /* Ajusta o tamanho para ocupar toda a largura disponível */
    max-width: 300px; /* Limita o tamanho máximo do botão */
    margin: 20px auto; /* Centraliza o botão no mobile */
    display: block; /* Garante que o botão esteja exibido como um bloco */
  }
`;

