import React from 'react';
import styled from 'styled-components';

const CTASection = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/558582025839', '_blank');
  };

  return (
    <CTAContainer>
      <CTAContent>
        <CTATitle>Pronto para economizar com energia solar?</CTATitle>
        <CTAText>Entre em contato conosco agora mesmo e descubra como a energia solar pode transformar sua vida!</CTAText>
        <CTAButton onClick={handleWhatsAppClick}>Solicite um Orçamento</CTAButton>
      </CTAContent>
    </CTAContainer>
  );
};

export default CTASection;

// Styled Components

const CTAContainer = styled.section`
  padding: 80px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  text-align: center;
`;

const CTAContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const CTAText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

const CTAButton = styled.a`
  padding: 15px 30px;
  font-size: 1.2rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.cardBackground};
  }
`;
