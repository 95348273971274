import React, { useState } from 'react';
import styled from 'styled-components';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // Aqui você pode adicionar lógica para enviar o formulário
  };
  
  return (
    <ContactContainer>
      <ContactInfo>
        <h2>Entre em Contato</h2>
        <p>Email: contato@gfsolucoes.com</p>
        <p>Telefone: (85)) 98202-5839</p>
        <p>Endereço: Rua R, 123 - Fortaleza, Ceara</p>
      </ContactInfo>

      <FormContainer>
        <h2>Envie uma Mensagem</h2>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Nome"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Textarea
            name="message"
            placeholder="Sua mensagem"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <SubmitButton type="submit">Enviar Mensagem</SubmitButton>
        </Form>

        {submitted && <ThankYouMessage>Obrigado por entrar em contato! Responderemos em breve.</ThankYouMessage>}
      </FormContainer>

      <MapContainer>
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.1941604761286!2d-38.479939571565886!3d-3.8580481997562206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7c75a9b4769bd8f%3A0x196d928801e64d18!2sR.%20C%C3%A1ssius%20Klay%2C%20165%20-%20S%C3%A3o%20Bento%2C%20Fortaleza%20-%20CE%2C%2060875-610!5e0!3m2!1spt-BR!2sbr!4v1726405934624!5m2!1spt-BR!2sbr"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </MapContainer>

      <SocialMedia>
        <h3>Conecte-se Conosco</h3>
        <SocialIcons>
          <SocialIcon href="https://facebook.com" target="_blank">Facebook</SocialIcon>
          <SocialIcon href="https://www.instagram.com/gfsolucoesengenharia?igsh=MTFxYXFmZ2s4YmRqeg==" target="_blank">Instagram</SocialIcon>
        </SocialIcons>
      </SocialMedia>
    </ContactContainer>
  );
};

export default Contact;

// Styled Components

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

const ContactInfo = styled.div`
  margin-bottom: 40px;
  h2 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.text};
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin-bottom: 40px;

  h2 {
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  outline: none;
  transition: border 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  outline: none;
  transition: border 0.3s ease;
  min-height: 150px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  font-size: 1.2rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ThankYouMessage = styled.p`
  margin-top: 20px;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin-top: 40px;
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const SocialMedia = styled.div`
  margin-top: 40px;

  h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.primary};

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const SocialIcon = styled.a`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    color: white;
  }
`;
