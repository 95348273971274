import React from 'react';
import styled from 'styled-components';
import projectImage1 from '../assets/project1.jpg';
import projectImage2 from '../assets/project2.jpg';
import projectImage3 from '../assets/project3.jpg';

const projects = [
  {
    title: 'Projeto Residencial',
    description: 'Sistemas fotovoltaicos instalados em residências para energia limpa e econômica.',
    image: projectImage1,
  },
  {
    title: 'Projeto Comercial',
    description: 'Instalações de energia solar em edifícios comerciais, proporcionando sustentabilidade.',
    image: projectImage2,
  },
  {
    title: 'Projeto Industrial',
    description: 'Soluções de energia solar para grandes indústrias, promovendo eficiência energética.',
    image: projectImage3,
  },
];

const Projects = () => {
  return (
    <ProjectsContainer>
      <SectionTitle>Projetos Realizados</SectionTitle>
      <ProjectsGrid>
        {projects.map((project, index) => (
          <ProjectCard key={index}>
            <ProjectImage src={project.image} alt={project.title} />
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
          </ProjectCard>
        ))}
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

export default Projects;

// Styled Components

const ProjectsContainer = styled.div`
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const ProjectsGrid = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const ProjectCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 15px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  width: 300px;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    height: 180px;
    margin-bottom: 15px;
  }
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text};

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;
