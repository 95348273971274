// src/pages/About.js
import React from 'react';
import styled from 'styled-components';
import aboutImage from '../assets/solar.jpg';

const About = () => {
  return (
    <AboutContainer>
      <ImageContainer>
        <img src={aboutImage} alt="Sobre nós" />
      </ImageContainer>
      <Content>
        <Title>Sobre Nós</Title>
        <Paragraph>
          A GF Soluções de Engenharia é líder em soluções de energia solar no Brasil, oferecendo tecnologia de ponta para residências, empresas e indústrias. Nossa missão é promover o uso sustentável da energia, garantindo economia e preservação do meio ambiente.
        </Paragraph>
        <Paragraph>
          Nossa equipe é composta por especialistas em energia renovável que estão comprometidos em entregar resultados excepcionais, sempre colocando o cliente no centro de nossas operações.
        </Paragraph>
      </Content>
    </AboutContainer>
  );
};

export default About;

// Styled Components

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;

  img {
    width: 100%;
    border-radius: 15px;
  }
`;

const Content = styled.div`
  max-width: 800px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.background};
  margin-bottom: 20px;
`;
