import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/558582025839', '_blank');
  };

  return (
    <ButtonContainer onClick={handleWhatsAppClick}>
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
    </ButtonContainer>
  );
};

// Estilização do botão
const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Sombra mais intensa ao passar o mouse */
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px; /* Tamanho menor em dispositivos móveis */
  }

  @media (max-width: 480px) {
    width: 45px;
    height: 45px;
  }
`;

export default WhatsAppButton;
