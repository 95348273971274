import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Simulator from './pages/Simulator';
import Header from './components/Header';
import Footer from './components/Footer';
import { MyContextProvider } from './context/MyContext';
import ErrorBoundary from './ErrorBoundary';  // Importa o ErrorBoundary
import { ThemeProvider } from 'styled-components';
import { theme } from '../src/styles/theme';
import WhatsAppButton from './components/WhatsAppButton'; // Verifique o caminho correto do arquivo

function App() {
  return (
    <ThemeProvider theme={theme}>
    <MyContextProvider>
      <Router>
        <ErrorBoundary>  {/* Envolvendo o app no ErrorBoundary */}
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/simulator" element={<Simulator />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </ErrorBoundary>
      </Router>
      <WhatsAppButton />
    </MyContextProvider>
    </ThemeProvider>
   
  );
}

export default App;
