import React, { useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com'; // Importa o EmailJS

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSending, setIsSending] = useState(false); // Para controlar o estado de envio

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true); // Inicia o estado de envio

    // Configuração do envio de e-mail via EmailJS
    emailjs
      .send(
        'your_service_id',   // Substitua pelo seu service_id
        'your_template_id',  // Substitua pelo seu template_id
        formData,
        'your_user_id'       // Substitua pelo seu user_id
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Mensagem enviada com sucesso!');
          setFormData({ name: '', email: '', message: '' }); // Limpa o formulário
        },
        (error) => {
          console.log(error.text);
          alert('Ocorreu um erro ao enviar a mensagem. Tente novamente.');
        }
      )
      .finally(() => {
        setIsSending(false); // Finaliza o estado de envio
      });
  };

  return (
    <SectionContainer>
      <SectionTitle>Entre em Contato</SectionTitle>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Seu nome"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Seu e-mail"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Textarea
          name="message"
          placeholder="Sua mensagem"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit" disabled={isSending}>
          {isSending ? 'Enviando...' : 'Enviar Mensagem'}
        </SubmitButton>
      </Form>
    </SectionContainer>
  );
};

export default ContactSection;

// Styled Components

const SectionContainer = styled.section`
  padding: 60px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 30px;
  }
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    max-width: 100%;
    gap: 15px;
    margin: 0 10px 0 10px;
  }
`;

const Input = styled.input`
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  outline: none;
  transition: border 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  font-size: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  height: 150px;
  outline: none;
  transition: border 0.3s ease;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryHover};
  }

  @media (max-width: 768px) {
    padding: 12px;
    height: 120px;
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  font-size: 1.2rem;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 1rem;
  }
`;

