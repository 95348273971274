import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ClientsSection = () => {
  const [clients, setClients] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setClients((prev) => {
        if (prev < 400) return prev + 1; // Simulando um contador até 250 clientes
        clearInterval(interval);
        return prev;
      });
    }, 50);

    return () => clearInterval(interval); // Limpa o intervalo após a animação
  }, []);

  return (
    <SectionContainer>
      <SectionTitle>Clientes Atendidos</SectionTitle>
      <CounterContainer>
        <Counter>{clients}+</Counter>
        <CounterDescription>Clientes Satisfeitos</CounterDescription>
      </CounterContainer>
      <TestimonialsContainer>
        <Testimonial>
          <p>"A instalação foi perfeita e a economia que tivemos é inacreditável!"</p>
          <Author>- João Silva</Author>
        </Testimonial>
        <Testimonial>
          <p>"Altamente recomendados! O time é muito profissional e o suporte foi impecável."</p>
          <Author>- Maria Oliveira</Author>
        </Testimonial>
        <Testimonial>
          <p>"Nosso sistema fotovoltaico foi um excelente investimento. Obrigado!"</p>
          <Author>- Pedro Santos</Author>
        </Testimonial>
      </TestimonialsContainer>
    </SectionContainer>
  );
};

export default ClientsSection;

// Styled Components

const SectionContainer = styled.section`
  width: 100%;
  padding: 60px 20px;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const CounterContainer = styled.div`
  margin-bottom: 40px;
`;

const Counter = styled.span`
  font-size: 4rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const CounterDescription = styled.p`
  font-size: 1.5rem;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 20px;
    margin: 0 10px 0 10px;
  }
`;

const Testimonial = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
    max-width: 90%;
  }
`;

const Author = styled.p`
  font-weight: bold;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`;
