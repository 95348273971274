import React from 'react';
import styled from 'styled-components';
import ServicesSection from '../components/ServicesSection';
import ProjectsSection from '../components/ProjectsSection';
import ClientsSection from '../components/ClientsSection';
import SimulatorSection from '../components/SimulatorSection';
import FAQSection from '../components/FAQSection';
import PartnershipSection from '../components/PartnershipSection';
import BlogSection from '../components/BlogSection';
import CTASection from '../components/CTASection';
import ContactSection from '../components/ContactSection';
import HeroSection from '../components/HeroSection';

const Home = () => {
  
  return (
    <HomeContainer>
      <HeroSection/>
      <ServicesSection/>
      <ProjectsSection/>
      <ClientsSection/>
      <SimulatorSection/>
      <PartnershipSection/>
      <BlogSection/>
      <CTASection/>
      <ContactSection/>
      <FAQSection/>
      {/* Outras seções seguem abaixo */}
    </HomeContainer>
  );
};

export default Home;

// Estilos com styled-components

const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
