import React from 'react';
import styled from 'styled-components';
import generatedImage from '../assets/image2.png'; // Adicione sua imagem gerada

const SimulatorSection = () => {
  return (
    <SectionContainer>
      <ContentWrapper>
        <ImageContainer>
          <img src={generatedImage} alt="Imagem gerada" />
        </ImageContainer>

        <CallToAction>
          <CallToActionTitle>Economize com Energia Solar</CallToActionTitle>
          <CallToActionText>
            Descubra quanto você pode economizar utilizando nossa solução de energia solar. Clique no botão abaixo para saber mais!
          </CallToActionText>
          <CallToActionButton
            href="https://azume.com.br/institucional/iframesrchttpsazumecombrsimulador645bd52a770a4b0014f497a7width100height600iframe" // Altere para o link desejado
            target="_blank"
            rel="noopener noreferrer"
          >
            Simule seu Kit de Energia Solar!
          </CallToActionButton>
        </CallToAction>
      </ContentWrapper>
    </SectionContainer>
  );
};

export default SimulatorSection;

// Styled Components

const SectionContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
 background-color: ${({ theme }) => theme.colors.background}; 
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 1;
  width: 80%;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 768px) {
    margin-top: 25%;
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  
  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const CallToAction = styled.div`
  flex: 1;
  display: flex;
  margin-left: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
    margin-bottom: 25%;
  }
`;

const CallToActionTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;

const CallToActionText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`;

const CallToActionButton = styled.a`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 12px 24px;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryHover};
  }
`;
