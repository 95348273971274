import React from 'react';
import styled from 'styled-components';
import installSolar from '../assets/install-solar.jpg';
import installSolar2 from '../assets/solar.jpg';
import installSolar3 from '../assets/project3.jpg';

const ServicesSection = () => {
  return (
    <SectionContainer id="about-section">
      <BackgroundShapes />
      <SectionTitle>Nossos Serviços</SectionTitle>
      <ServicesGrid>
        <ServiceCard>
          <ServiceIcon src={installSolar2} alt="Instalação" />
          <ServiceTitle>Instalação de Painéis Solares</ServiceTitle>
          <ServiceDescription>
            Oferecemos a instalação de painéis solares com alta eficiência e tecnologia de ponta.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon src={installSolar} alt="Manutenção" />
          <ServiceTitle>Manutenção</ServiceTitle>
          <ServiceDescription>
            Garantimos a manutenção periódica dos sistemas para manter sua eficiência.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon src={installSolar3} alt="Consultoria" />
          <ServiceTitle>Consultoria Energética</ServiceTitle>
          <ServiceDescription>
            Ajudamos você a identificar as melhores soluções de energia solar para o seu projeto.
          </ServiceDescription>
        </ServiceCard>
      </ServicesGrid>
    </SectionContainer>
  );
};

export default ServicesSection;

// Styled Components

const SectionContainer = styled.section`
  padding: 100px 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    right: -50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    transform: skewY(-4deg);
    z-index: 0;
  }
`;

const BackgroundShapes = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;

  &::before, &::after {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary};/* Cor laranja translúcida */
    border-radius: 50%;
    z-index: -1;
    animation: float 6s ease-in-out infinite;
  }

  &::before {
    width: 400px;
    height: 400px;
    top: -150px;
    left: -200px;
    animation-duration: 10s;
  }

  &::after {
    width: 300px;
    height: 300px;
    bottom: -100px;
    right: -150px;
    animation-duration: 8s;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(30px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    &::before {
      width: 200px;
      height: 200px;
      top: -100px;
      left: -100px;
    }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 60px;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  z-index: 1;
`;

const ServicesGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
`;

const ServiceCard = styled.div`
  background-color: ${({ theme }) => theme.colors.cardBackground};
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 350px;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.cardHover};
  }
`;

const ServiceIcon = styled.img`
  width: 320px;
  height: 320px;
  border-radius: 15px;
  margin-bottom: 25px;
  object-fit: cover;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const ServiceTitle = styled.h3`
  font-size: 1.7rem;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.secondary};
`;

const ServiceDescription = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.6;
`;
